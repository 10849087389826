<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].display.label"
                        vid="display"
                        :label="beForm[rep].display.label"
                        v-model="form[rep].display"
                        placeholder="Inserisci un codice"
                        :rules="getRules('display')"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].importer_id.label"
                        vid="importer_id"
                        :label="beForm[rep].importer_id.label"
                        v-model="form[rep].importer_id"
                        :options="beForm[rep].importer_id.options"
                        :rules="getRules('importer_id')"
                        @input="onInputImporterId"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        label="Descrizione Compagnia"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        name="
                           Escludi ricalcolo ramo danni extra auto
                        "
                        vid="exclude_calc_extra_vehicle_branch"
                        label="
                          Escludi ricalcolo ramo danni extra auto
                        "
                        v-model="form.exclude_calc_extra_vehicle_branch"
                        :options="options"
                        :disabled="!form[rep].importer_id"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        name="Escludi ricalcolo ramo vita"
                        vid="exclude_calc_life_branch"
                        label="Escludi ricalcolo ramo vita"
                        v-model="form.exclude_calc_life_branch"
                        :options="options"
                        :disabled="!form[rep].importer_id"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        name="Escludi ricalcolo ramo auto"
                        vid="exclude_calc_vehicle_branch"
                        label="Escludi ricalcolo ramo auto"
                        v-model="form.exclude_calc_vehicle_branch"
                        :options="options"
                        :disabled="!form[rep].importer_id"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <b-card
                header="Flusso contabilità fiscale"
                header-tag="header"
                class="mt-2"
                v-if="canVerb('fattutto', 'config')"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        :name="beForm.fattutto.active.label"
                        vid="fattutto_active"
                        groupLabel=""
                        :label="beForm.fattutto.active.label"
                        v-model="form.fattutto.active"
                        :chk_val="true"
                        :unchk_val="false"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm.fattutto.code.label"
                        vid="fattutto_code"
                        :label="beForm.fattutto.code.label"
                        v-model="form.fattutto.code"
                        placeholder="Inserisci un codice"
                        textcase="upper"
                        :rules="getCustomRules('fattutto_code')"
                      /></div
                  ></b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseIcon from "@/components/BaseIcon";
import CustomInputs from "@/components/form/CustomInputs";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import ShowMixin from "@/mixins/ShowMixin";
import { mapGetters } from "vuex";
import { setFormValues } from "@/utils/forms";
import { canVerb } from "@/utils/permissions";

export default {
  mixins: [FormMixin, ShortcutMixin, ShowMixin],
  data() {
    return {
      repository: "insurer",
      id: this.$route.params.id,
      item: this.$route.params.item,
      options: [
        { text: "Si", value: "Y" },
        { text: "No", value: "N" },
      ],
      details: {},
      form: {
        exclude_calc_extra_vehicle_branch: null,
        exclude_calc_life_branch: null,
        exclude_calc_vehicle_branch: null,
        insurer: {
          display: null,
          importer_id: null,
          title: null,
        },
        fattutto: {
          code: "",
          active: false,
        },
      },
    };
  },
  components: {
    BaseIcon,
    CustomInputs,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
  },
  methods: {
    canVerb,
    getCustomRules(field) {
      let rules = {};
      if (field === "fattutto_code") {
        if (this.form.fattutto.active) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      }
      return rules;
    },
    onSubmit() {
      this.isLoading = true;
      console.log(this.setPayload());
      this.update(this.repository, this.id)
        .then((response) => {
          this.addtoInsurer(
            response.data.data.id,
            "risk_type",
            this.setPayload()
          )
            .then(() => {
              // this.$store.dispatch("auth/insurers");
              // this.$showSnackbar({
              //   preset: "success",
              //   text: `Azione Completata: Compagnia Modificata`,
              // });
              // this.shortcut("module.PROF", null, "#Companies", "filterInsurer");
              /* SAVE HERE FATTUTTO CONFIG with a new API call */
              const Repo = RepositoryFactory.get("fattutto");
              const payload = {
                configurable_id: this.id,
                configurable_type: "insurer",
                code: this.form.fattutto.code,
                ledger_type: 3, // no ledger_type
                // note: "",
                active: this.form.fattutto.active,
              };
              (this?.details?.id
                ? Repo.update_configuration(this.details.id, payload)
                : Repo.store_configuration(payload)
              )
                .then(() => {
                  // this.$store.dispatch("auth/mandate_codes");
                  this.$store.dispatch("auth/insurers");
                  this.$showSnackbar({
                    preset: "success",
                    text: `Azione Completata: Compagnia Modificata`,
                  });
                  this.shortcut(
                    "module.PROF",
                    null,
                    "#Companies",
                    "filterInsurer"
                  );
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                  this.isLoading = false;
                  // .finally(() => {
                  //   this.isLoading = false;
                  // });
                });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    defaultBranchConfig() {
      const riskTypesPivot = this.item.risk_types;
      this.form.exclude_calc_vehicle_branch = riskTypesPivot.find(
        (risk) => risk.code === "A"
      ).pivot.is_excluded.value;
      this.form.exclude_calc_life_branch = riskTypesPivot.find(
        (risk) => risk.code === "V"
      ).pivot.is_excluded.value;
      this.form.exclude_calc_extra_vehicle_branch = riskTypesPivot.find(
        (risk) => risk.code === "D"
      ).pivot.is_excluded.value;
    },
    onInputImporterId(val) {
      if (!val) {
        this.defaultBranchConfig();
      }
    },
    addtoInsurer(insurer_id, relation, payload) {
      const Repo = RepositoryFactory.get("insurer");
      return Repo.pivot_update(insurer_id, relation, payload);
    },
    setPayload() {
      return {
        risk_type: {
          [this.getRisks().find((risk) => risk.code === "A").value]: {
            is_excluded: this.form.exclude_calc_vehicle_branch,
          },
          [this.getRisks().find((risk) => risk.code === "V").value]: {
            is_excluded: this.form.exclude_calc_life_branch,
          },
          [this.getRisks().find((risk) => risk.code === "D").value]: {
            is_excluded: this.form.exclude_calc_extra_vehicle_branch,
          },
        },
      };
    },
    ...mapGetters("auth", {
      getRisks: "risks",
    }),
  },
  created() {
    this.isLoading = true;
    this.fetchShowForm(this.repository, this.id)
      .then((response) => {
        console.log("response", response);
        const data = response.data.data?.fattutto_configuration?.[0] ?? {};
        this.details = data;
        this.fetchEditForm(this.repository, this.id).then(() => {
          this.beForm.fattutto = {};
          this.beForm["fattutto"].active = {
            label: `Compagnia attiva all'esportazione per contabilità fiscale`,
            type: "select",
            value: this.form.fattutto.active,
          };
          this.beForm["fattutto"].code = {
            label: `Codice Compagnia per flusso esportazione contabilità fiscale`,
            type: "text",
            value: this.form.fattutto.code,
          };
          if (Object.entries(this.details).length) {
            this.beForm["fattutto"].active.value = Boolean(this.details.active);
            this.beForm["fattutto"].code.value = this.details.code;
          }
          this.form["fattutto"] = setFormValues(
            ["active", "code"],
            this.beForm["fattutto"]
          );
          this.defaultBranchConfig();
          this.isLoading = false;
        });
      })
      .catch((error) => {
        this.form = {};
        console.error(error);
        this.isLoading = false;
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
